import {
	createI18n
} from "vue-i18n";

import en from './en-US'
import zh from './zh-CN'


const i18n = new createI18n({

	locale: 'en-US', //设置默认语言
	messages: {
		'zh-CN': zh,
		'en-US': en
	}
});

export default i18n;