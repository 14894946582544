// main.js页面
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vue from "vue";
import i18n from "@/language";
import Vant from "vant";
import "vant/lib/index.css";

router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  next();
});

// 跳转后返回顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
createApp(App).use(store).use(router).use(Vant).use(i18n).mount("#app");
