import http from '@/utlis/http'
import axios from "axios";
export default {

	searchTrack: data => {
		return http({
			url: '/api/v1.order/searchTrack',
			data
		}).then(res => res);
	},
	MessageAdd: data => {
		return http({
			url: '/api/v1.MessageBoard/add',
			data
		}).then(res => res);
	},
	search: data => {
		return new Promise((resolve,reject) => {
			axios.post('https://api.dsc56.com/api/admin/v1/order/searchOrder',data).then(res => {
				resolve(res.data)
			})
		})

	}

}
