<template>
  <div class="head">
    <van-row justify="space-between" class="heard">
      <van-col span="20">
        <div class="logo">
          <van-image src="/logo.png" class="logo-img" />
          <span class="logo-title" @click="Home">
            {{ $t("message.LogoTitle") }}
          </span>
        </div>
      </van-col>
      <van-col span="4" style="text-align: right">
        <van-icon
          @click.stop="TakeNav"
          name="wap-nav"
          class="logo-icon"
          size="30px"
        />
      </van-col>
    </van-row>
  </div>
  <transition name="topToTop" v-if="openflag" appear>
    <van-row class="nav block">
      <van-col
        :span="24"
        v-for="item in navlist"
        :key="item.value"
        @click.stop="Tacksites(item)"
      >
        <div class="nav-title">			{{ $t(item.label) }}</div>
      </van-col>
      <van-col span="24" class="language">
        <span class="lug" @click="lang('zh-CN')">{{
          $t("message.Chinese")
        }}</span>
        <van-divider
          vertical
          :hairline="false"
          :style="{ borderColor: '#1989fa' }"
        />
        <span class="lug" @click="lang('en-US')">English</span>
      </van-col>
    </van-row>
  </transition>
</template>

<script setup>
import { onMounted, ref, defineProps, getCurrentInstance } from "vue";

import { useRoute, useRouter } from "vue-router";
import api from "@/api";
import store from "@/store";
const { ctx } = getCurrentInstance();
const lang = (type) => {
  if (type === "zh-CN") {
    ctx.$i18n.locale = "zh-CN";

    localStorage.setItem("lang", "zh-CN");
  } else {
    ctx.$i18n.locale = "en-US";

    localStorage.setItem("lang", "en-US");
  }
  openflag.value=false
};

const route = useRoute();
const router = useRouter();
//导航栏展示
const openflag = ref(false);
const TakeNav = () => {
  openflag.value = !openflag.value;
};

//导航栏
const navlist = ref([
	{ value: 1, label: 'message.home' },
	{ value: 2, label: 'message.OurServices' },
	{ value: 3, label: 'message.ContactUs' },
	{ value: 4, label: 'message.New' },
	{ value: 5, label: 'message.CargoTracking' }
]);
const Tacksites = (item) => {
  if (item.value === 1) {
    router.push({
      path: "/",
    });
  }
  if (item.value === 2) {
    router.push({
      name: "serve",
    });
  }

  if (item.value === 3) {
    router.push({
      name: "contact",
    });
  }
  if (item.value === 4) {
    router.push({
      name: "news",
    });
  }
  if (item.value === 5) {
    router.push({
      name: "order",
    });
  }

  store.dispatch("base/up_orderdetail", null);
  store.dispatch("base/up_newdetail", null);

  openflag.value = false;
};

const Home = () => {
  router.push({
    path: "/",
  });
  store.dispatch("base/up_orderdetail", null);
};

onMounted(() => {});
</script>

<style lang="scss" scoped>
.heard {
  padding: 20px;
  position: relative;
  overflow: hidden;
  z-index: 9999;
  background-color: #ffffff;
  .logo {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo-img {
      width: 100px;
      height: auto;
    }
    .logo-title {
      font-size: 36px;
      font-weight: bold;
      width: 100%;
      padding-left: 20px;
    }
  }
  .logo-icon {
    height: 100px;
    line-height: 100px;
  }
}
.block {
  position: relative;
  width: 100%;
  text-align: center;
  line-height: 100px;

}

/*滑入——从顶部*/
@keyframes slideIn_top {
  0% {
    top: -100%;
  }

  100% {
    top: 0;
  }
}

/*滑出——从顶部*/
// @keyframes slideOut_top {
// 	0% {
// 		top: 0;
// 	}

// 	100% {
// 		top: -100%;
// 	}
// }

/*(滑入)——从顶部滑入,从顶部滑出*/
.topToTop-enter-active {
  animation: slideIn_top 1s;
}
/*(滑出)——从顶部滑入,从顶部滑出*/
.topToTop-leave-active {
  animation: slideOut_top 1s;
}
.language {
}
.nav-title {
  width: 100%;
  height: 100px;
  padding-left: 20px;
  font-size: 30px;
  text-align: left;
  line-height: 100px;
  background: #fff;
  border-bottom: 1px solid gray;
}
</style>