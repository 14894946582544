import axios from "axios";
import store from "../store/index.js";
import router from "../router/index.js";
import {
	baseUrl
} from '../utlis/config'


const http = ({
	url,
	data = "",
	op = true
}) => {
	//axios 拦截器
	axios.interceptors.request.use(
		function(config) {
			let user = store.getters["base/get_user"];
			if (user != null) {
				config.headers.authorization = user.token;
			}
			return config;
		},
		function(err) {
			return Promise.reject(err);
		}
	);

	return new Promise((resolve, reject) => {
		axios.post(baseUrl + url, data).then((res) => {
			if (res.data.code === -2000) {
				router.push({
					path: '/login'
				})
			}
			// if (op && res.data.code === 0) {
			// 	Notification.info({
			// 		type: "success",
			// 		title: "成功",
			// 	})

			// }
			// if (op && res.data.code !== 0) {

			// 	Notification.info({
			// 		type: "error",
			// 		title: "系统提示",
			// 		content: res.data.msg,
			// 	})
			// }
			resolve(res.data);
		});
	});
};

export default http;