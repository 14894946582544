import {
	createRouter,
	createWebHistory
} from "vue-router";
import Home from "../views/home.vue";

const routes = [{
		path: "/",
		name: "home",
		component: Home,
	},
	{
		path: "/serve",
		name: "serve",
		component: () => import('@/views/serve.vue')
	},
	{
		path: "/contact",
		name: "contact",
		component: () => import('@/views/contact.vue')
	},
	{
		path: "/news",
		name: "news",
		component: () => import('@/views/news.vue')
	},
	{
		path: "/order",
		name: "order",
		component: () => import('@/views/order.vue')
	},


];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

//注入动态路由

export default router;