<template>
  <div class="footer" v-if="contactList.length != 0">

    <van-list>
      <van-cell class="contactList-box">
        <div class="flex-box">
          {{ $t("message.Nigeria") }}
          <div></div>
        </div>

        <div class="flex-box">
          <div>
            <span style="font-weight: bold"
              >{{ $t("message.contacts") }}：</span
            >
            <span v-if="ctx.$i18n.locale === 'zh-CN'">{{
              contactList[0].contacts_name
            }}</span>
            <span v-if="ctx.$i18n.locale !== 'zh-CN'">{{
              contactList[1].contacts_name
            }}</span>
          </div>
          <div>
            <span style="font-weight: bold"
              >{{ $t("message.contactInformation") }}：</span
            >
            <span v-if="ctx.$i18n.locale === 'zh-CN'">{{
              contactList[0].contact
            }}</span>
            <span v-if="ctx.$i18n.locale !== 'zh-CN'">{{
              contactList[1].contact
            }}</span>
          </div>
        </div>
        <div style="text-align: left" v-if=" contactList[0].email != '' || contactList[1].email ">

        <span style="font-weight: bold">{{ $t("message.EMail") }}：</span>
          {{contactList[0].email != '' ?contactList[0].email:contactList[1].email}}

        </div>
      </van-cell>
      <van-cell class="contactList-box">
        <div class="flex-box">
          {{ $t("message.Nigeria") }}
          <div></div>
        </div>

        <div class="flex-box">
          <div>
            <span style="font-weight: bold"
            >{{ $t("message.contacts") }}：</span
            >
            <span v-if="ctx.$i18n.locale === 'zh-CN'">{{
                contactList[6].contacts_name
              }}</span>
            <span v-if="ctx.$i18n.locale !== 'zh-CN'">{{
                contactList[7].contacts_name
              }}</span>
          </div>
          <div>
            <span style="font-weight: bold"
            >{{ $t("message.contactInformation") }}：</span
            >
            <span v-if="ctx.$i18n.locale === 'zh-CN'">{{
                contactList[6].contact
              }}</span>
            <span v-if="ctx.$i18n.locale !== 'zh-CN'">{{
                contactList[7].contact
              }}</span>
          </div>
        </div>
       <div style="text-align: left" v-if="contactList[6].email != '' || contactList[7].email != ''">
         <span style="font-weight: bold">{{ $t("message.EMail") }}：</span>

         {{contactList[6].email != '' ?contactList[6].email:contactList[7].email}}
       </div>
      </van-cell>
      <van-cell class="contactList-box">
        <div class="flex-box">
          {{ $t("message.China") }}
          <div></div>
        </div>

        <div class="flex-box">
          <div>
            <span style="font-weight: bold"
            >{{ $t("message.contacts") }}：</span
            >
            <span v-if="ctx.$i18n.locale === 'zh-CN'">{{
                contactList[2].contacts_name
              }}</span>
            <span v-if="ctx.$i18n.locale !== 'zh-CN'">{{
                contactList[3].contacts_name
              }}</span>
          </div>
          <div>
            <span style="font-weight: bold"
            >{{ $t("message.contactInformation") }}：</span
            >
            <span v-if="ctx.$i18n.locale === 'zh-CN'">{{
                contactList[2].contact
              }}</span>
            <span v-if="ctx.$i18n.locale !== 'zh-CN'">{{
                contactList[3].contact
              }}</span>
          </div>
        </div>
          <div style="text-align: left" v-if="contactList[2].email != '' || contactList[3].email != ''">

        <span style="font-weight: bold">{{ $t("message.EMail") }}：</span>

            {{contactList[2].email != ''?contactList[2].email:contactList[3].email}}
        </div>
      </van-cell>
      <van-cell class="contactList-box">
        <div class="flex-box">
          {{ $t("message.Indonesia") }}
          <div></div>
        </div>

        <div class="flex-box">
          <div>
            <span style="font-weight: bold"
            >{{ $t("message.contacts") }}：</span
            >
            <span v-if="ctx.$i18n.locale === 'zh-CN'">{{
                contactList[4].contacts_name
              }}</span>
            <span v-if="ctx.$i18n.locale !== 'zh-CN'">{{
                contactList[5].contacts_name
              }}</span>
          </div>
          <div>
            <span style="font-weight: bold"
            >{{ $t("message.contactInformation") }}：</span
            >
            <span v-if="ctx.$i18n.locale === 'zh-CN'">{{
                contactList[4].contact
              }}</span>
            <span v-if="ctx.$i18n.locale !== 'zh-CN'">{{
                contactList[5].contact
              }}</span>
          </div>
        </div>
          <div style="text-align: left" v-if="contactList[4].email != '' ||contactList[5].email != ''">

        <span style="font-weight: bold">{{ $t("message.EMail") }}：</span>
       {{contactList[4].email != '' ?contactList[4].email:contactList[5].email}}
        </div>
      </van-cell>
    </van-list>
    <div class="footer-title">2021 {{ $t("message.Copyright") }}</div>
  </div>
</template>

<script setup>
import { onMounted, ref, getCurrentInstance } from "vue";

import api from "@/api";
import store from "@/store";
const { ctx } = getCurrentInstance();
const activeName = ref(1);

const contactList = ref([]);
const Getcontact = () => {
  api.news.syslist().then((res) => {
    contactList.value = res.data;
    console.log(contactList.value, 222);
  });
};

onMounted(() => {
  Getcontact();
});
</script>
<style>
:root {
  --van-collapse-item-content-background: rgb(53, 52, 54) !important;
  --van-collapse-item-content-padding: 0;
}
</style>
<style lang="scss" scoped>
.footer {
  background-color: rgb(53, 52, 54);
  color: gray;
  padding: 20px;
}

.flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-title {
  font-size: 20px;
  padding: 20px 0;
}
.contactList-box {
  font-size: 20px;
  background-color: rgb(53, 52, 54) !important;
}
</style>
