<template>
  <Heard  />
  <!-- 轮播图 -->
  <van-swipe :autoplay="3000" lazy-render>
    <van-swipe-item v-for="item in bannerList" :key="item.id"
      ><img :src="item.url" class="swipe-img"
    /></van-swipe-item>
  </van-swipe>
  <!-- 订单查询 -->
  <div class="search-banner">

    <!--    <div class="search-title">运单号查询</div>-->
    <form action="/">
      <van-search
        v-model="order_no"
        :placeholder=" ctx.$i18n.locale ==='en-US'?'Enter your tracking number':'输入您的快递单号'"
        @search="onSearch"
        class="seearch-input"
        style="max-height: 40px; min-height: 40px; font-size: 16px"
      >
        <template #left-icon>
          <div></div>
        </template>
        <template #right-icon>
          <van-icon
            name="search"
            style="font-size: 18px; padding-right: 10px"
          />
        </template>
      </van-search>
    </form>
  </div>

  <!-- 服务 -->
  <div class="main-title">
    <span class="title">{{ $t('message.ServiceItems') }}</span>
    <span class="en-title">Service Items</span>
  </div>
  <van-grid :column-num="2" :border="false">
    <van-grid-item v-for="(item, index) in servrlist" :key="index">
      <van-image :src="item.url" fit="cover" class="server-img" />
      <div class="server-title">
        <span v-if="ctx.$i18n.locale === 'zh-CN'">{{ item.title_cn }}</span>
        <span v-if="ctx.$i18n.locale === 'en-US'">{{ item.title_en }}</span>
      </div>
    </van-grid-item>
  </van-grid>
  <div class="main-title">
    <span class="title">{{ $t('message.NewsCenter') }}</span>
    <span class="en-title">News Center</span>
  </div>

  <van-swipe lazy-render :autoplay="4000">
    <van-swipe-item v-for="(item, index) in NewsItems" :key="index" @click.stop="TakeNews(item)">
      <div class="news-box">
        <div class="news-title">{{ item.title }}</div>

        <div class="van-multi-ellipsis--l2 news-msg">
          {{ item.content.replace(/<[^>]*>/g, "") }}
        </div>
      </div>

      <van-image fit="cover" :src="item.image" class="news-img" />
    </van-swipe-item>
  </van-swipe>

  <div class="main-title">
    <span class="title">{{ $t('message.PopularTransportation') }}</span>
    <span class="en-title">Popular transportation</span>
  </div>
  <van-grid :column-num="2" :border="false">
    <van-grid-item>
      <van-image src="/sucai1.png" fit="cover" />
      <div class="box-title">{{ $t('message.SeaTransportation') }}</div>
    </van-grid-item>
    <van-grid-item>
      <van-image src="/sucai2.png" fit="cover" />
      <div class="box-title">{{ $t('message.RoadTransportation') }}</div>
    </van-grid-item>
  </van-grid>
  <Footer  />

</template>

<script setup>
import { onMounted, ref,getCurrentInstance } from "vue";
import { useRoute, useRouter } from "vue-router";
import Heard from "@/components/Heard.vue";
import Footer from "@/components/Footer.vue";
import api from "@/api";
import store from "@/store";
const { ctx } = getCurrentInstance();




const route = useRoute();
const router = useRouter();
//轮播图
const bannerList = ref([]);
const Getbaner = () => {
  api.news
    .getList({
      type: 1,
      page: 1,
      row: 999,
    })
    .then((res) => {
      bannerList.value = res.data;
    });
};
const order_no = ref("");
const orderdetail = ref(null);
const onSearch = (val) => {
  api.order.searchTrack({ order_no: val }).then((res) => {
    if (res.code === 0) {
      orderdetail.value = res.data;
      store.dispatch("base/up_orderdetail", res.data);
      router.push({
        name: "order",
      });
    }else{
      store.dispatch("base/up_orderdetail", null);
      router.push({
        name: "order",
      });
    }
  });
};
const servrlist = ref([]);
const GetServe = () => {
  api.news
    .projectList({
      type: 1,
      page: 1,
      row: 999,
    })
    .then((res) => {
      servrlist.value = res.data;
    });
};
const NewsItems = ref([]);
const GetNews = () => {
  api.news.news
    .list({
      text: "",
      page: 1,
      row: 999,
    })
    .then((res) => {
      NewsItems.value = res.data.data;
    });
};
const TakeNews = val =>{
  store.dispatch("base/up_newdetail", val);
  router.push({
    name: "news",
  });
}
onMounted(() => {
  Getbaner();
  GetServe();
  GetNews();
});
</script>

<style lang="scss" scoped>
.van-swipe-item .swipe-img {
  width: 100%;
  height: 380px;
}

.search-banner {
  background-color: rgb(125, 155, 67);
  transform: translateY(-10px);
  padding: 20px;
}
.search-title {
  color: white;
  text-align: left;
  padding: 10px 0;
  font-weight: bold;
}
.main-title {
  text-align: left;
  padding: 20px;
  .title {
    font-size: 40px;
    font-weight: bold;
  }
  .en-title {
    margin-left: 20px;
    font-size: 24px;
  }
}
.server-img {
  height: 300px;
  width: 100%;
}
.server-title {
  height: 50px;
  line-height: 50px;
  background-color: #7d9b43;
  color: #ffffff;
  width: calc(100% - 30px);
  position: absolute;

  bottom: 16px;
}
.news-box {
  background-color: #f6f6f6;
  padding: 10px;
}
.news-title {
  font-size: 28px;
  font-weight: bold;
}
.news-msg {
  font-size: 18px;
}
.news-img {
  width: 100%;
  height: 400px;
}
.box-title {
  width: calc(100% - 32px);
  font-size: 18px;
  color: #ffffff;
  line-height: 63px;
  background: #000000;
  opacity: 0.59;
  position: absolute;
  z-index: 99;
  bottom: 30px;
}
</style>
