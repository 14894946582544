const state = { orderdetail: null,
newdetail:null};
const mutations = {
    set_orderdetail(state, data) {
        state.orderdetail = data;
    },
    set_newdetail(state, data) {
        state.newdetail = data;
    },
};
const actions = {
  up_orderdetail({ commit }, data) {
    commit("set_orderdetail", data);
  },
  up_newdetail({ commit }, data) {
    commit("set_newdetail", data);
  },
};
const getters = {
    get_orderdetail(state) {
        return state.orderdetail;
    },
    get_newdetail(state) {
        return state.newdetail;
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
